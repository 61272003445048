.App-projects-parent{
    background-color: #333;
    color:white;
    padding: 10px;
}

.App-projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #333;
    color: white;
  }
  