.App-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    height: 95vh;
    background-color: #333;
    color: white;
    overflow: hidden;
  }
  
  .App-header-left {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  

  .App-header-middle-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding-top: 20vh;
    padding-bottom: 20vh;
  }
  
  .App-header-right {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .App-header-right a {
    margin-left: 10px;
    color: white;
    text-decoration: none;
  }

  .App-header-right a:hover {
    cursor: pointer;
  }

  .scroll-button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.5rem;
    padding: 10px 20px;
    border: 2px solid #fff;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
  }
  
  .scroll-button:hover {
    cursor: pointer;
  }

  .neon-wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
  }
  
  .neon-wave svg {
    width: 100%;
    height: 200%;
  }
  
  .neon-wave path {
    fill: none;
    stroke: url(#gradient);
    stroke-width: 1;
    stroke-linecap: round;
    opacity: 0.4;
  }
  
  
  
  
  

  
  