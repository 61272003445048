.App-about-parent{
    background-color: rgb(250, 250, 250);
    padding: 50px;
}
.App-about {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 50px;
    background-color: #333;
    color: white;
    border: 1px solid white;
    border-radius: 20px;
  }
  
  .App-about-left {
    flex: 1;
    padding-right: 50px;
  }
  
  .App-about-right {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .App-about-right img {
    max-width: 100%;
    height: auto;
  }
  
  .App-about-divider {
    width: 80%;
  }
  
  .profile-pic {
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 250px;
    height: 250px;
  }
  
  .App-about-download{
    color:white;
    margin-top: 20px;
  }

  .download-button {
    margin-top: 30px;
    display: inline-block;
    padding: 12px 24px;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease;
  }
  
  .download-button:hover {
    background-color: #0062cc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
    transform: translateY(-2px);
  }