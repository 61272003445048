.App {
  text-align: center;
}

.About-section,
.Projects-section,
.Contact-section {
  margin-top: 50px;
  margin-bottom: 50px;
}

.About-section h2,
.Projects-section h2,
.Contact-section h2 {
  font-size: 2em;
  margin-bottom: 0.5em;
  background-color: aqua;
}

.About-section p,
.Projects-section p,
.Contact-section p {
  font-size: 1.2em;
  line-height: 1.5;
  max-width: 800px;
  margin: 0 auto;
}
