.project-link {
    text-decoration: none;
    color:white;
  }
.project-container {
    background-color: rgb(85, 85, 85);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 600px;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  }

  .project-container:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px);
  }
  
  .project-description {
    flex: 1;
    padding: 20px;
  }

  .description-text{
    text-align: justify;
  }

.project-image {
    width: 100px;
    height: 100px;
    border-radius: 10px;
  }

  .github-logo {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }